function validateAffiliateForm() {
    $("#affiliate-application").validate({
        errorPlacement: function(error, element) {
            if (element.attr("name") == "radio_station_affiliate_application[commercial]") {
                error.insertAfter("#affiliate_application_radio_station");
            } else if (element.attr("name") == "radio_station_affiliate_application[fixed_schedule]") {
                error.insertAfter("#fixed_schedule");
            } else if (element.attr("name") == "radio_station_affiliate_application[airing_ros]") {
                error.insertAfter("#airing_ros");
            } else if (element.attr("name") == "radio_station_affiliate_application[terms_consent]") {
                error.insertAfter("#terms_consent");
            } else if (element.attr("name") == "radio_station_affiliate_application[spots_air_online]") {
                error.insertAfter("#spots_air_online");
            } else if (element.attr("name") == "radio_station_affiliate_application[starts_airing_on(1i)]") {
                error.insertAfter("#starts_airing_on");
            } else if (element.attr("name") == "radio_station_affiliate_application[starts_airing_on(2i)]") {
                error.insertAfter("#starts_airing_on");
            } else if (element.attr("name") == "radio_station_affiliate_application[starts_airing_on(3i)]") {
                error.insertAfter("#starts_airing_on");
            } else {
                error.insertAfter(element);
            }
        },
        rules: {
            'radio_station_affiliate_application[radio_format]': "required",
            'radio_station_affiliate_application[radio_frequency]': {
                required: notOnlyOnline,
            },
            'radio_station_affiliate_application[call_letters]': {
                required: notOnlyOnline,
            },
            'radio_station_affiliate_application[aqh_listeners]': "required",
            'radio_station_affiliate_application[commercial]': "required",
            'radio_station_affiliate_application[website]': {
                required: true,
                url: true
            },
            'radio_station_affiliate_application[street_address_1]': "required",
            'radio_station_affiliate_application[city]': "required",
            'radio_station_affiliate_application[country]': "required",
            'radio_station_affiliate_application[state]': "required",
            'radio_station_affiliate_application[postal_code]': "required",
            'radio_station_affiliate_application[starts_airing_on(1i)]': "required",
            'radio_station_affiliate_application[starts_airing_on(2i)]': "required",
            'radio_station_affiliate_application[starts_airing_on(3i)]': "required",
            'radio_station_affiliate_application[fixed_schedule]': "required",
            'radio_station_affiliate_application[air_days_and_times]': {
                required: function() { return $('[name="radio_station_affiliate_application[fixed_schedule]"]:checked').val() == "true" }
            },
            'radio_station_affiliate_application[airing_ros]': "required",
            'radio_station_affiliate_application[estimated_airings_per_day]': {
                required: function() { return $('[name="radio_station_affiliate_application[airing_ros]"]:checked').val() == "true" }
            },
            'radio_station_affiliate_application[spots_air_online]': "required",
            'radio_station_affiliate_application[online_stream_times]': {
                required: airOnlineIsTrue,
            },
            'radio_station_affiliate_application[air_value_per_spot]': "required",
            'radio_station_affiliate_application[requested_spot_length]': "required",
            'radio_station_affiliate_application[preferred_program_media_delivery_method]': "required",
            'radio_station_affiliate_application[applicant_name]': "required",
            'radio_station_affiliate_application[applicant_title]': "required",
            'radio_station_affiliate_application[primary_contact_name]': "required",
            'radio_station_affiliate_application[primary_contact_email]': {
                required: true,
                email: true
            },
            'radio_station_affiliate_application[primary_contact_phone]': "required",
            'radio_station_affiliate_application[secondary_contact_name]': "required",
            'radio_station_affiliate_application[secondary_contact_email]': {
                required: true,
                email: true
            },
            'radio_station_affiliate_application[secondary_contact_phone]': "required",
            'radio_station_affiliate_application[terms_consent]': "required",
        },
        messages: {
            'radio_station_affiliate_application[radio_format]': "Please select your radio format",
            'radio_station_affiliate_application[radio_frequency]': "Please enter your radio frequency if not airing online",
            'radio_station_affiliate_application[call_letters]': "Please enter your call letters if not airing online",
            'radio_station_affiliate_application[aqh_listeners]': "Please enter the amount of your AQH Listeners",
            'radio_station_affiliate_application[commercial]': "This field is required",
            'radio_station_affiliate_application[website]': "Please enter a valid website",
            'radio_station_affiliate_application[street_address_1]': "Please enter your street address",
            'radio_station_affiliate_application[city]': "Please enter your city",
            'radio_station_affiliate_application[country]': "Please select your country",
            'radio_station_affiliate_application[state]': "Please select your state",
            'radio_station_affiliate_application[postal_code]': "Please enter your postal code",
            'radio_station_affiliate_application[starts_airing_on(1i)]': "Please select a year. ",
            'radio_station_affiliate_application[starts_airing_on(2i)]': "Please select a month. ",
            'radio_station_affiliate_application[starts_airing_on(3i)]': "Please select a day. ",
            'radio_station_affiliate_application[fixed_schedule]': "Please indicate whether you air on a fixed schedule",
            'radio_station_affiliate_application[air_days_and_times]': "Please enter the air days and times if on fixed schedule",
            'radio_station_affiliate_application[air_ros]': "This field is required",
            'radio_station_affiliate_application[estimated_airings_per_day]': "This field is required if spots air ROS",
            'radio_station_affiliate_application[spots_air_online]': "This field is required",
            'radio_station_affiliate_application[online_stream_times]': "This field is required if streaming online",
            'radio_station_affiliate_application[air_value_per_spot]': "Please enter the air value for each spot",
            'radio_station_affiliate_application[requested_spot_length]': "Please enter the air value for each spot",
            'radio_station_affiliate_application[preferred_program_media_delivery_method]': "Please select how you want to receive the programs",
            'radio_station_affiliate_application[applicant_name]': "Please enter your name",
            'radio_station_affiliate_application[applicant_title]': "Please enter your title",
            'radio_station_affiliate_application[primary_contact_name]': "Please enter a primary contact name",
            'radio_station_affiliate_application[primary_contact_email]': "Please enter a valid primary contact email",
            'radio_station_affiliate_application[primary_contact_phone]': "Please enter a primary contact phone",
            'radio_station_affiliate_application[secondary_contact_name]': "Please enter a secondary contact name",
            'radio_station_affiliate_application[secondary_contact_email]': "Please enter a valid secondary contact email",
            'radio_station_affiliate_application[secondary_contact_phone]': "Please enter a secondary contact phone",
            'radio_station_affiliate_application[terms_consent]': "Please select yes or no",
        },
        submitHandler: function(form) {
            if (checkForCaptcha()) {
                $("#affiliate-application :submit").prop("disabled", "disabled");
                $("#affiliate-application :submit").css('color', 'white');
                $("#affiliate-application :submit").css('cursor', 'wait');
                $("#affiliate-application :submit").css('background-color', '#646463');
                $("#affiliate-application :submit").css('border-color', '#646463');
                $("#affiliate-application :submit").val('Saving...');
                form.submit();
            }
        }
    });
}
function airOnlineIsTrue() {
    return $('[name="radio_station_affiliate_application[spots_air_online]"]:checked').val() == "true";
}
function notOnlyOnline() {
    return $('[name="radio_station_affiliate_application[radio_format]"]').val() != "online";
}
window.validateAffiliateForm = validateAffiliateForm;