function checkForCaptcha() {
    console.log("Checking for captcha");
    if (grecaptcha.getResponse() == "") {
        console.log("Showing captcha notice");
        $('#human_check_notice').show();
        return false;
    } else {
        console.log("Hiding captcha notice");
        $('#human_check_notice').hide();
        return true;
    }
}

function renderCallback() {
    $('#human_check_notice').hide();
}

function renderCaptcha() {
    widget_id = grecaptcha.render('recaptcha-container', {
        'sitekey': "6LdDtNsmAAAAACDknGAgc5qauAxHnULrXRtBdV_L"
    });
};

window.renderCaptcha = renderCaptcha;
window.checkForCaptcha = checkForCaptcha;
window.renderCallback = renderCallback;