function validateContactUsForm() {
  $("#contact-us-form").validate({
    rules: {
      "family_minute_contact_submission[name]": "required",
      "family_minute_contact_submission[email]": {
        required: true,
        email: true
      },
      "family_minute_contact_submission[city_state]": "required",
      "family_minute_contact_submission[comments]": "required"
    },
    messages: {
      "family_minute_contact_submission[name]": "Please enter your full name",
      "family_minute_contact_submission[email]": "Please enter a valid email",
      "family_minute_contact_submission[city_state]": "Please enter your city and state",
      "family_minute_contact_submission[comments]": "The field is required"
    },
    submitHandler: function(form) {
      if (checkForCaptcha()) {
        $("#contact-us-form :submit").prop("disabled", "disabled");
        $("#contact-us-form :submit").css('color', 'white');
        $("#contact-us-form :submit").css('cursor', 'wait');
        $("#contact-us-form :submit").css('background-color', '#646463');
        $("#contact-us-form :submit").css('border-color', '#646463');
        $("#contact-us-form :submit").val('Saving...');
        form.submit();
      }
    }
  })
}
window.validateContactUsForm = validateContactUsForm;
