$(document).ready(function() {
    //back to top
    $(window).scroll(function () {
        if ($(window).scrollTop() > 100) {
            if (!$('#back_to_top').hasClass('show'))
                $('#back_to_top').addClass('show');
            if (!$('#back_to_top').hasClass('scroll'))
                $('#back_to_top').addClass('scroll');
        }
        else
            $('#back_to_top').removeClass('show');
    });
    $(window).on('scrollstop', function () {
        $('#back_to_top').removeClass('scroll');
    });
    if ($(window).scrollTop() > 100) $('#back_to_top').addClass('show');
    else $('#back_to_top').removeClass('show');
    $('#back_to_top').click(function () {
        $('html,body').stop().animate({scrollTop: 0}, 1000);
        return false;
    });

    //search form
    $('.magnet-search .magnet-icon-search,.magnet-search .magnet-icon-search-close').click(function(e){
        e.stopPropagation();
        $(this).parent().toggleClass('open');
    });
    $('.magnet-search').click(function(e){
        e.stopPropagation();
    });
    $(document).click(function(e) {
        $('.magnet-search form').removeClass('open');
    });
});