/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Rails from "@rails/ujs"

Rails.start()

import jQuery from "jquery"
global.$ = global.jQuery = jQuery
window.$ = window.jQuery = jQuery
import "jquery-validation/dist/jquery.validate"
require("jquery-ui")
require("jquery.jplayer.min")
require("sweetalert")
require("bootstrap")
require("validate_affiliate_form")
require("validate_contact_us_form")
import "recaptcha.js.erb"
import scrollReveal from "scrollreveal"
window.scrollReveal = global.scrollReveal = scrollReveal
require("custom")
